import { createSharedComposable, useStorage } from '@vueuse/core';
import { useLicenceStore } from "~/store/useLicenceStore";

const _useUIApp = () => {
    const { isDevis } = useLicenceStore();
    const { establishment, links } = useAppConfig();
    const breadcrumbCategory = useStorage('breadcrumbCategory', '', sessionStorage);
    const navbarApp = [
        {
            idCategory: 1,
            nameCategory: "Tableau de bord",
            imageCategory: "dashboard",
            fontAwesome: "",
            link: links.dashboard,
            disabled: false,
            subCategories: [],
        },
        {
            idCategory: 2,
            nameCategory: "Mon activité",
            imageCategory: "pencil",
            fontAwesome: "",
            link: "/activite",
            subCategories: [
                {
                    idSubCategory: 3,
                    nameSubCategory: "Mes véhicules",
                    linkSubCategory: links.vehicle,

                },
                {
                    idSubCategory: 4,
                    nameSubCategory: "Mes commandes",
                    linkSubCategory: links.orders,
                },
                {
                    idSubCategory: 5,
                    nameSubCategory: "Mes devis",
                    linkSubCategory: links.devisHistory,
                    disabled: !isDevis(establishment.configuration.isDevis)
                },
                {
                    idSubCategory: 6,
                    nameSubCategory: "Mes clients",
                    linkSubCategory: links.ListCustomer,
                    disabled: false
                },
                {
                    idSubCategory: 7,
                    nameSubCategory: "Mes rendez-vous",
                    linkSubCategory: "",
                    disabled: true

                }
            ]
        },
        {
            idCategory: 8,
            nameCategory: "Catalogue",
            imageCategory: "catalog",
            alternativeImageCategory: "",
            link: links.catalog,
            isVehicleNeeded: true,
            subCategories: []
        },
        {
            idCategory: 9,
            nameCategory: "Boutique",
            imageCategory: "shop",
            alternativeImageCategory: "",
            link: links.shop,
            disabled: !establishment.configuration.isShop,
            subCategories: !establishment.configuration.isShop? [] : [
                {
                    idSubCategory: 8,
                    nameSubCategory: "Entretien",
                    linkSubCategory: links.shopEntretien,

                },
                {
                    idSubCategory: 9,
                    nameSubCategory: "Outillage",
                    linkSubCategory: links.shopOutillage,
                },
                {
                    idSubCategory: 10,
                    nameSubCategory: "Additifs / Lubrifiants",
                    linkSubCategory: links.devisHistory,
                    disabled: false
                },
                {
                    idSubCategory: 11,
                    nameSubCategory: "Électricité",
                    linkSubCategory: links.ListCustomer,
                    disabled: false
                },
                {
                    idSubCategory: 12,
                    nameSubCategory: "Équipement Atelier",
                    linkSubCategory: links.orders,
                    disabled: false

                },
                {
                    idSubCategory: 12,
                    nameSubCategory: "Peinture",
                    linkSubCategory: links.orders,
                    disabled: false

                },
                {
                    idSubCategory: 12,
                    nameSubCategory: "Accessoires",
                    linkSubCategory: links.orders,
                    disabled: false

                },
                {
                    idSubCategory: 12,
                    nameSubCategory: "Carrosserie",
                    linkSubCategory: links.orders,
                    disabled: false

                }
            ]
            // subCategories: [],
        },
        {
            idCategory: 10,
            nameCategory: "Pneumatique",
            imageCategory: "tire",
            alternativeImageCategory: "",
            link: links.tyres,
            disabled: !establishment.configuration.isPneumatic,

            subCategories: [],
        },
        {
            idCategory: 11,
            nameCategory: "Gestion atelier",
            imageCategory: "clede10",
            alternativeImageCategory: "",
            link: "/gestion-atelier",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 12,
                    nameSubCategory: "Planning rendez-vous",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 13,
                    nameSubCategory: "Ordre de mission",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 14,
                    nameSubCategory: "Suivi heures facturées",
                    linkSubCategory: "",
                    disabled: true

                }
            ],
        },
        {
            idCategory: 15,
            nameCategory: "Mon site internet",
            imageCategory: "fa-regular-home",
            alternativeImageCategory: "",
            link: "/my-site",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 16,
                    nameSubCategory: "Actualités",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 17,
                    nameSubCategory: "Promotions",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 18,
                    nameSubCategory: "Diaporama",
                    linkSubCategory: "",
                    disabled: true

                },
                {
                    idSubCategory: 19,
                    nameSubCategory: "Véhicules d’occasion",
                    linkSubCategory: " ",
                    disabled: true

                },
                {
                    idSubCategory: 20,
                    nameSubCategory: "Recrutement",
                    linkSubCategory: "",
                    disabled: true

                }
            ],
        },
        {
            idCategory: 21,
            nameCategory: "Statistiques",
            imageCategory: "circle-plus",
            alternativeImageCategory: "",
            link: "/stats",
            disabled: true,
            subCategories: [
                {
                    idSubCategory: 22,
                    nameSubCategory: "Plaques",
                    linkSubCategory: "",
                    disabled: true

                },

            ],
        },
    ]

    return {
        breadcrumbCategory,
        navbarApp
    };
}

export const useUIApp = createSharedComposable(_useUIApp)